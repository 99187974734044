<!-- 
	This is the sign in page, it uses the dashboard layout in: 
	"./layouts/Default.vue" .
 -->

 <template>
	<div class="bg-gray-3">
		
		<a-row :gutter="[24,24]" class="mt-0 pt-0" v-if="windowWidth > 991">

			<a-col :span="24" :md="24" :lg="24" :xl="24" v-if="!loadingSession" class="pt-0 mt-0">

				<a-card :bordered="false" class="card-info px-0 mx-0 mt-0 pt-0" style="padding-top: 75px !important"  id="home">

					<a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" style="padding-top: 0px;">
						
						<a-col :span="24" :md="18" :lg="18" :xl="18" class="col-form pt-20 mt-0">

							<a-row :gutter="[24,24]">
								<a-col :span="24" :md="1" :lg="1"></a-col>
								<a-col :span="24" :md="10" :lg="10" class="">
									<!-- <a-row class="text-center">
										<a-col :lg="24">
											<img src="../../../public/images/icon_2.png" alt="" width="80px" height="80px" class="" style="border-radius: 20px; background-color: #54398D;"> 
										</a-col>
									</a-row> -->
									<h1 class="" style="font-weight: 100; font-size: 40px;">Welcome Back, login to continue Learning!</h1>
									<!-- <p class="text-muted text-center mt-0 pt-0">Login to continue</p> -->

									<a-alert
										v-if="error.show"
										:message="error.message"
										type="error"
										closable
										:after-close="handleClose"
										show-icon 
									/>

									<a-form
										id="components-form-demo-normal-login"
										:form="form"
										class="login-form"
										@submit="handleSubmit"
										:hideRequiredMark="true">
										<a-input-group>
											<a-row :gutter="24">
												<a-col :span="5" class="w-100 pr-0 mr-0">
													<a-form-item class="mb-10 w-100" label="Username" :colon="false">
														<vue-country-code
															style="height: 40px; width: 100%; margin-top: 4px;"
															@onSelect="onSelectCountry"
															:enableSearchField="true"
															defaultCountry="tz">
														</vue-country-code>
															<!-- preferredCountries="['tz', 'ke', 'ug', 'za']" -->
													</a-form-item>
												</a-col>
												<a-col :span="19" class="ml-0 pl-1">
													<a-form-item class="mb-10" :colon="false">
														<a-input 
															style="height: 40px; margin-top: 42px; width: 100%;"
															v-decorator="[
															'username',
															{ rules: [{ required: true, message: 'Please input your username!' }] },
															]" placeholder="Email or Phone number" />
													</a-form-item>
												</a-col>
											</a-row>
											
										</a-input-group>
										
										<a-form-item class="mb-5" label="Password" :colon="false">
											<a-input-password
												id="signinInputPassword"
												v-decorator="['password', { rules: [{ required: true, message: 'Please input your password!' }] }, ]" 
												style=""
												type="password" 
												placeholder="Password" />
										</a-form-item>

										<a-row :gutters="24" class="pb-5">
											<a-col :span="24" class="pb-5">
												<a class="text-primary" style="text-decoration: none !important;" :href="`/forgot-password`">
													<span>Forgot password?</span>
												</a>
											</a-col>
										</a-row>

										<a-form-item class="mt-2">
											<a-button :loading="isLoading" type="primary" block html-type="submit" class="login-form-button mt-10 text-white" style="border-radius: 15px; height: 46px;">
												LOGIN
											</a-button>
										</a-form-item>


										<a-row :gutters="24" class="pb-5">
											<a-col :span="24" class="pb-5 text-center">
												<span>New to Silabu?</span>
											</a-col>
										</a-row>
										

										<a-form-item class="mt-2">
											<a-button id="btnBordered" type="primary" block class="login-form-button mt-10 text-white" @click="toSignUpPage" style="border-radius: 20px;">
												Create Account
											</a-button>
										</a-form-item>

										<!-- <a-row :gutters="24" class="pb-5">
											<a-col :span="24" class="pb-5 text-center">
												<a-button id="floatingBtn3" type="primary" @click="toWhatsappLink">
													<font-awesome-icon icon="fa-brands fa-whatsapp" class="" style="font-size: 18px; padding-right: 3px"/><span style="font-size: 14px;">Call us</span>
												</a-button>
											</a-col>
										</a-row> -->

									</a-form>
								</a-col>


								<a-col :span="24" :md="1" :lg="1"></a-col>
								
								<a-col type="flex" :span="24" :md="12" :lg="12" align="middle" class="text-center  justify-items-center mt-20 pt-20">
									<img src="../../../public/images/person_studying.png" alt="" width="100%" style="border-radius: 20px;"> 
								</a-col>
								
							</a-row>
						</a-col>
					</a-row>
				</a-card>

				<a-card :bordered="false" class="card-info px-0 mx-0 mt-0 pt-0" style="background-color: #F7F7F7; padding-bottom: 60px" id="student">

					<a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" style="padding-top: 0px;">
						
						<a-col :span="24" :md="18" :lg="18" :xl="18" class="col-form pt-20 mt-10 px-0">

							<a-row :gutter="[24,24]">
								<a-col :span="24" :md="1" :lg="1"></a-col>
								<a-col :span="24" :md="22" :lg="22" class="px-20 mx-0">

									<a-row :gutter="24">

										<a-col :span="11" class="mt-20 pt-20">
											<label class="" style="font-size: 15px; font-weight: 500; color: #848484;">Silabu for Students</label> 
											<p class="mt-0 pt-0 mb-0 pb-10"></p>

											<label class="mt-15" style="font-size: 26px; font-weight: 700;">Have Fun Learning</label>

											<a-row :gutter="24" class="">
												<a-col :span="24" class="mt-10">
													<a-list item-layout="horizontal">
														<template>
														<a-list-item>
															<a-list-item-meta description="Engage with peers, teachers, and lessons seamlessly, anytime, anywhere">
																<template #title>
																	Learn with Friends
																</template>
																<template #avatar>
																	<a-avatar id="card" class="mt-5 bg-white" shape="square" :size="42" style="background: #F4F3FC;">
																		<img src="../../../public/images/friends.png" alt="" width="100%" style="j"> 
																	</a-avatar>
																</template>
															</a-list-item-meta>
														</a-list-item>
														</template>
													</a-list>
												</a-col>

												<a-col :span="24" class="mt-10">
													<a-list item-layout="horizontal">
														<template>
														<a-list-item>
															<a-list-item-meta description="Access a wealth of study materials and resources">
																<template #title>
																	Vast Resource Library
																</template>
																<template #avatar>
																	<a-avatar id="card" class="mt-5 bg-white" shape="square" :size="42" style="background: #F4F3FC;">
																		<img src="../../../public/images/library.png" alt="" width="100%" style=""> 
																	</a-avatar>
																</template>
															</a-list-item-meta>
														</a-list-item>
														</template>
													</a-list>
												</a-col>


												<a-col :span="24" class="mt-10">
													<a-list item-layout="horizontal">
														<template>
														<a-list-item>
															<a-list-item-meta description="Learn from skilled educators dedicated to your success">
																<template #title>
																	Expert Tutoring
																</template>
																<template #avatar>
																	
																	<a-avatar id="card" class="mt-5 bg-white" shape="square" :size="42" style="background: #F4F3FC;">
																		<img src="../../../public/images/expert.png" alt="" width="100%" style=""> 
																	</a-avatar>
																</template>
															</a-list-item-meta>
														</a-list-item>
														</template>
													</a-list>
												</a-col>

												<!-- <a-col :span="24" class="text-center mt-20 pt-20 pb-20 pr-20">
													<a-button id="btnBordered" type="text" style="border-radius: 20px; width: 50%;">
														More &nbsp;&nbsp; <font-awesome-icon icon="fa-solid fa-chevron-down" class="text-primary mr-20" style="padding-bottom: 4px; margin-left: 5px;"/>
													</a-button>
												</a-col> -->

											</a-row>
										</a-col>

										<a-col :span="13" class="mt-20 pt-20">
											<img src="../../../public/images/student_web.png" alt="" width="" style="height: 360px; border-radius: 10px"> 
										</a-col>

									</a-row>

								
									<a-row :gutter="24" class="" style="margin-top: 60px">
										<a-col :span="24" class="mt-10 px-0 mx-0 bg-white mt-20 mb-0 pb-0">
											<a-carousel autoplay arrows :dots="true" class="mb-0 pb-0" dotsClass="text-primary" style="background-color: #F7F7F7 !important">

												<template #prevArrow>
													<div class="custom-slick-arrow" style="left: 10px; z-index: 1; color: white">
														<font-awesome-icon icon="fa-solid fa-chevron-left" class="text-primary" style="color: #656090 !important; font-size: 25px; position: absolute; top: -2px"/>
													</div>
												</template>
												<template #nextArrow>
													<div class="custom-slick-arrow" style="right: 10px; color: white">
														<font-awesome-icon icon="fa-solid fa-chevron-right" class="text-primary" style="color: #656090 !important; font-size: 25px; position: absolute; top: -2px"/>
													</div>
												</template>


												<div class="px-20 pt-10 mb-0 pb-0" style="background-color: #F7F7F7 !important">
													<a-row :gutter="24" class="">
														<a-col :span="4" class=""></a-col>
														<a-col :span="4" class="px-20 pt-15 mt-10">
															<img src="../../../public/images/student_1.jpg" alt="" width="" style="height: 160px; width: 160px; border-radius: 80px"> 
															<!-- <a-avatar src="../../../public/images/student.png" :size="150" style=""/> -->
														</a-col>
														<a-col :span="2" class=""></a-col>
														<a-col id="cardCarosel" :span="10" class="px-20 py-20 mt-10">
															<span style="font-weight: 400; color: #54398D !important; font-size: 14px;">
																“ Introducing Suzana Dastan, a recent graduate of Twiga Secondary School's CSEE 
																program with a Division 2:18 pass. At the young age of 17, she has already set 
																her sights on becoming a medical doctor and has taken the initiative to enroll 
																in Pre-Form 5 classes offered by SILABU APP. ”
															</span>
															<br/>
															<br/>
															<span class="mt-20" style="font-size: 15px; font-weight: 500; color: #54398D;">
																Suzana Dastan,<i> Student Form 4 </i>
															</span>
														</a-col>
														<a-col :span="4" class=""></a-col>
													</a-row>
												</div>

											</a-carousel>
										</a-col>

										<a-col :span="24" class="mt-20 pt-20 text-center">
											<a-button type="primary" @click="navigateTo('home')" style="margin-top: 40px; border-radius: 10px; font-size: 16px; padding-left: 50px; padding-right: 50px; height: 45px" class="">
												Start Learning Now
											</a-button>
										</a-col>
									</a-row>

								</a-col>
								<a-col :span="24" :md="1" :lg="1"></a-col>
								
							</a-row>
						</a-col>
					</a-row>
				</a-card>

				<a-card :bordered="false" class="card-info px-0 mx-0 mt-0 pt-0" style="background-color: #FFFFFF; padding-bottom: 60px" id="parent">

					<a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" style="padding-top: 0px;">
						
						<a-col :span="24" :md="18" :lg="18" :xl="18" class="col-form pt-20 mt-10 px-0">

							<a-row :gutter="[24,24]">
								<a-col :span="24" :md="1" :lg="1"></a-col>
								<a-col :span="24" :md="22" :lg="22" class="px-20 mx-0">
									
									<a-row :gutter="48">

										<a-col :span="13" class="mt-20 pt-20">
											<img src="../../../public/images/parent_web.png" alt="" width=""  style="height: 360px; border-radius: 10px"> 
										</a-col>

										<a-col :span="11" class="mt-20 pt-20">
											<label style="font-size: 15px; font-weight: 500; color: #848484">Silabu for Parents</label>
											<p class="mt-0 pt-0 mb-0 pb-10"></p>
											<label style="font-size: 26px; font-weight: 700;">Take Control of Your Child’s Education</label>

											<a-row :gutter="24" class="">
												<a-col :span="24" class="mt-10">
													<a-list item-layout="horizontal">
														<template>
														<a-list-item>
															<a-list-item-meta description="Access a network of skilled educators dedicated to your children's success">
																<template #title>
																	Expert Teachers
																</template>
																<template #avatar>
																	<a-avatar id="card" class="mt-5 bg-white" shape="square" :size="42" style="background: #F4F3FC;">
																		<img src="../../../public/images/expert.png" alt="" width="100%" style=""> 
																	</a-avatar>
																</template>
															</a-list-item-meta>
														</a-list-item>
														</template>
													</a-list>
												</a-col>

												<a-col :span="24" class="mt-10">
													<a-list item-layout="horizontal">
														<template>
														<a-list-item>
															<a-list-item-meta description="Stay informed. Track your child's progress with our Progress Reports. Real insights, real improvement">
																<template #title>
																	Progress Insights
																</template>
																<template #avatar>
																	<a-avatar id="card" class="mt-5 bg-white" shape="square" :size="42" style="background: #F4F3FC;">
																		<img src="../../../public/images/progress.png" alt="" width="100%" style=""> 
																	</a-avatar>
																</template>
															</a-list-item-meta>
														</a-list-item>
														</template>
													</a-list>
												</a-col>


												<a-col :span="24" class="mt-10">
													<a-list item-layout="horizontal">
														<template>
														<a-list-item>
															<a-list-item-meta description="Effortlessly oversee multiple children's education in one place">
																<template #title>
																	Manage with Ease
																</template>
																<template #avatar>
																	
																	<a-avatar id="card" class="mt-5 bg-white" shape="square" :size="42" style="background: #F4F3FC;">
																		<img src="../../../public/images/manage.png" alt="" width="100%" style=""> 
																	</a-avatar>
																</template>
															</a-list-item-meta>
														</a-list-item>
														</template>
													</a-list>
												</a-col>

											</a-row>
										</a-col>


									</a-row>

								
									<a-row :gutter="24" class="" style="margin-top: 60px">
										<a-col :span="24" class="mt-10 px-0 mx-0 bg-white mt-20 mb-0 pb-0">
											<a-carousel autoplay arrows :dots="true" class="mb-0 pb-0" dotsClass="text-primary" style="background-color: #FFFFFF !important">

												<template #prevArrow>
													<div class="custom-slick-arrow" style="left: 10px; z-index: 1; color: white">
														<font-awesome-icon icon="fa-solid fa-chevron-left" class="text-primary" style="color: #656090 !important; font-size: 25px; position: absolute; top: -2px"/>
													</div>
												</template>
												<template #nextArrow>
													<div class="custom-slick-arrow" style="right: 10px; color: white">
														<font-awesome-icon icon="fa-solid fa-chevron-right" class="text-primary" style="color: #656090 !important; font-size: 25px; position: absolute; top: -2px"/>
													</div>
												</template>


												<div class="px-20 pt-10 mb-0 pb-0" style="background-color: #FFFFFF !important">
													<a-row :gutter="24" class="">
														<a-col :span="4" class=""></a-col>
														<a-col :span="4" class="px-20 pt-15 mt-5">
															<img src="../../../public/images/parent_1.jpeg" alt="" width="" style="height: 160px; width: 160px; border-radius: 80px"> 
														</a-col>
														<a-col :span="2" class=""></a-col>
														<a-col id="cardCarosel" :span="10" class="px-20 py-20 mt-10">
															<span style="font-weight: 400; color: #54398D !important; font-size: 14px;">
																“  SILABU has been a game changer for my child's education. The program's personalized 
																approach to tutoring has helped my child understand difficult concepts and improve their 
																grades. I am so impressed with the progress my child has made thanks to SILABU. ”
															</span>
															<br/>
															<br/>
															<span class="mt-20" style="font-size: 15px; font-weight: 500; color: #54398D;">
																Joyce Lucas,<i> Parent </i>
															</span>
														</a-col>
														<a-col :span="4" class=""></a-col>
													</a-row>
												</div>

												<div class="px-20 pt-10 mb-0 pb-0" style="background-color: #FFFFFF !important">
													<a-row :gutter="24" class="">
														<a-col :span="4" class=""></a-col>
														<a-col :span="4" class="px-20 pt-15 mt-5">
															<img src="../../../public/images/parent_2.jpeg" alt="" width="" style="height: 160px; width: 160px; border-radius: 80px"> 
														</a-col>
														<a-col :span="2" class=""></a-col>
														<a-col id="cardCarosel" :span="10" class="px-20 py-20 mt-10">
															<span style="font-weight: 400; color: #54398D !important; font-size: 14px;">
																“  SILABU has been a lifesaver for my child's education. The program's personalized approach 
																to tutoring has helped my child excel in their studies and achieve their academic goals. 
																I highly recommend SILABU to any parent looking for a high-quality and effective tutoring program. ”
															</span>
															<br/>
															<br/>
															<span class="mt-20" style="font-size: 15px; font-weight: 500; color: #54398D;">
																Joyce Lucas,<i> Parent </i>
															</span>
														</a-col>
														<a-col :span="4" class=""></a-col>
													</a-row>
												</div>

											</a-carousel>
										</a-col>

										<a-col :span="24" class="mt-20 pt-20 text-center">
											<a-button type="primary" @click="navigateTo('home')" style="margin-top: 40px; border-radius: 10px; font-size: 16px; padding-left: 50px; padding-right: 50px; height: 45px" class="">
												Request a Tutor Now
											</a-button>
										</a-col>
									</a-row>

								</a-col>
								<a-col :span="24" :md="1" :lg="1"></a-col>
								
							</a-row>
						</a-col>
					</a-row>
				</a-card>

				<a-card :bordered="false" class="card-info px-0 mx-0 mt-0 pt-0" style="background-color: #F7F7F7; padding-bottom: 60px" id="teacher">

					<a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" style="padding-top: 0px;">
						
						<a-col :span="24" :md="18" :lg="18" :xl="18" class="col-form pt-20 mt-10 px-0">

							<a-row :gutter="[24,24]">
								<a-col :span="24" :md="1" :lg="1"></a-col>
								<a-col :span="24" :md="22" :lg="22" class="px-20 mx-0">
									

									<a-row :gutter="24">

										<a-col :span="11" class="mt-20 pt-20">

											<label style="font-size: 15px; font-weight: 500; color: #848484">Silabu for Teachers</label>

											<p class="mt-0 pt-0 mb-0 pb-10"></p>

											<label style="font-size: 26px; font-weight: 700;">Earn a Living Doing What You Love</label>

											<a-row :gutter="24" class="">
												<a-col :span="24" class="mt-10">
													<a-list item-layout="horizontal">
														<template>
														<a-list-item>
															<a-list-item-meta description="Get paid for your tutoring services with no delays">
																<template #title>
																	Instant Payments
																</template>
																<template #avatar>
																	<a-avatar id="card" class="mt-5 bg-white" shape="square" :size="42" style="background: #F4F3FC;">
																		<img src="../../../public/images/wallet.png" alt="" width="100%" style=""> 
																	</a-avatar>
																</template>
															</a-list-item-meta>
														</a-list-item>
														</template>
													</a-list>
												</a-col>

												<a-col :span="24" class="mt-10">
													<a-list item-layout="horizontal">
														<template>
														<a-list-item>
															<a-list-item-meta description="Reach students worldwide, breaking down geographical barriers">
																<template #title>
																	Expanded Reach
																</template>
																<template #avatar>
																	<a-avatar id="card" class="mt-5 bg-white" shape="square" :size="42" style="background: #F4F3FC;">
																		<img src="../../../public/images/expanded.png" alt="" width="100%" style=""> 
																	</a-avatar>
																</template>
															</a-list-item-meta>
														</a-list-item>
														</template>
													</a-list>
												</a-col>


												<a-col :span="24" class="mt-10">
													<a-list item-layout="horizontal">
														<template>
														<a-list-item>
															<a-list-item-meta description="Streamline administrative tasks, like scheduling and progress tracking">
																<template #title>
																	Efficiency Boost
																</template>
																<template #avatar>
																	
																	<a-avatar id="card" class="mt-5 bg-white" shape="square" :size="42" style="background: #F4F3FC;">
																		<img src="../../../public/images/efficiency.png" alt="" width="100%" style=""> 
																	</a-avatar>
																</template>
															</a-list-item-meta>
														</a-list-item>
														</template>
													</a-list>
												</a-col>

											</a-row>
										</a-col>

										<a-col :span="13" class="mt-20 pt-20">
											<img src="../../../public/images/teacher_web.png" alt="" width="" style="height: 360px; border-radius: 10px"> 
										</a-col>

									</a-row>

								
									<a-row :gutter="24" class="" style="margin-top: 60px">
										<a-col :span="24" class="mt-10 px-0 mx-0 bg-white mt-20 mb-0 pb-0">
											<a-carousel autoplay arrows :dots="true" class="mb-0 pb-0" dotsClass="text-primary" style="background-color: #F7F7F7 !important">

												<template #prevArrow>
													<div class="custom-slick-arrow" style="left: 10px; z-index: 1; color: white">
														<font-awesome-icon icon="fa-solid fa-chevron-left" class="text-primary" style="color: #656090 !important; font-size: 25px; position: absolute; top: -2px"/>
													</div>
												</template>
												<template #nextArrow>
													<div class="custom-slick-arrow" style="right: 10px; color: white">
														<font-awesome-icon icon="fa-solid fa-chevron-right" class="text-primary" style="color: #656090 !important; font-size: 25px; position: absolute; top: -2px"/>
													</div>
												</template>


												<div class="px-20 pt-10 mb-0 pb-0" style="background-color: #F7F7F7 !important">
													<a-row :gutter="24" class="">
														<a-col :span="4" class=""></a-col>
														<a-col :span="4" class="px-20 pt-15 mt-5">
															<img src="../../../public/images/teacher_1.jpeg" alt="" width="" style="height: 160px; width: 160px; border-radius: 80px"> 
														</a-col>
														<a-col :span="2" class=""></a-col>
														<a-col id="cardCarosel" :span="10" class="px-20 py-20 mt-10">
															<span style="font-weight: 400; color: #54398D !important; font-size: 14px;">
																“ Ever since I joined SilabuApp, I have been receiving many students who need to be taught the Kiswahili subject. Silabu has also connected me with many parents whom I would not have met and taught their children without Silabu. Indeed, Silabu is a one-stop centre for many in the field of education. ”
															</span>
															<br/>
															<br/>
															<span class="mt-20" style="font-size: 15px; font-weight: 500; color: #54398D;">
																Mwl. Maeda,<i> Teacher at Open University TZ </i>
															</span>
														</a-col>
														<a-col :span="4" class=""></a-col>
													</a-row>
												</div>

											</a-carousel>
										</a-col>

										<a-col :span="24" class="mt-20 pt-20 text-center">
											<a-button type="primary" @click="navigateTo('home')" style="margin-top: 40px; border-radius: 10px; font-size: 16px; padding-left: 50px; padding-right: 50px; height: 45px" class="">
												Start Teaching Now
											</a-button>
										</a-col>
									</a-row>

								</a-col>
								<a-col :span="24" :md="1" :lg="1"></a-col>
								
							</a-row>
						</a-col>
					</a-row>
				</a-card>

				<a-card :bordered="false" class="card-info px-0 mx-0 mt-0 pt-0" style="background-color: #FFFFFF; padding-bottom: 60px" id="school">

					<a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" style="padding-top: 0px;">
						
						<a-col :span="24" :md="18" :lg="18" :xl="18" class="col-form pt-20 mt-10 px-0">

							<a-row :gutter="[24,24]">
								<a-col :span="24" :md="1" :lg="1"></a-col>
								<a-col :span="24" :md="22" :lg="22" class="px-20 mx-0">
									

									<a-row :gutter="48">

										<a-col :span="13" class="mt-20 pt-20">
											<img src="../../../public/images/school_web.png" alt="" width="" style="height: 360px; border-radius: 10px"> 
										</a-col>

										<a-col :span="11" class="mt-20 pt-20">
											<label style="font-size: 15px; font-weight: 500; color: #848484">Silabu for Schools</label>
											<p class="mt-0 pt-0 mb-0 pb-10"></p>
											<label style="font-size: 26px; font-weight: 700;">Take Education to Places Beyond Reach</label>

											<a-row :gutter="24" class="">
												<a-col :span="24" class="mt-10">
													<a-list item-layout="horizontal">
														<template>
														<a-list-item>
															<a-list-item-meta description="Seamlessly transition to online learning for enhanced reach and efficiency">
																<template #title>
																	Digitize classes
																</template>
																<template #avatar>
																	<a-avatar id="card" class="mt-5 bg-white" shape="square" :size="42" style="background: #F4F3FC;">
																		<img src="../../../public/images/online.png" alt="" width="100%" style=""> 
																	</a-avatar>
																</template>
															</a-list-item-meta>
														</a-list-item>
														</template>
													</a-list>
												</a-col>

												<a-col :span="24" class="mt-10">
													<a-list item-layout="horizontal">
														<template>
														<a-list-item>
															<a-list-item-meta description="Easily oversee multiple classes, teachers, and students from one platform">
																<template #title>
																	Effortless Management
																</template>
																<template #avatar>
																	<a-avatar id="card" class="mt-5 bg-white" shape="square" :size="42" style="background: #F4F3FC;">
																		<img src="../../../public/images/manage.png" alt="" width="100%" style=""> 
																	</a-avatar>
																</template>
															</a-list-item-meta>
														</a-list-item>
														</template>
													</a-list>
												</a-col>


												<a-col :span="24" class="mt-10">
													<a-list item-layout="horizontal">
														<template>
														<a-list-item>
															<a-list-item-meta description="Tailor courses and content to meet the unique needs of your institution">
																<template #title>
																	Customized Lessons
																</template>
																<template #avatar>
																	
																	<a-avatar id="card" class="mt-5 bg-white" shape="square" :size="42" style="background: #F4F3FC;">
																		<img src="../../../public/images/equalizer.png" alt="" width="100%" style=""> 
																	</a-avatar>
																</template>
															</a-list-item-meta>
														</a-list-item>
														</template>
													</a-list>
												</a-col>

											</a-row>
										</a-col>

										

									</a-row>

								
									<a-row :gutter="24" class="" style="margin-top: 60px">
										<!-- <a-col :span="24" class="mt-10 px-0 mx-0 bg-white mt-20 mb-0 pb-0">
											<a-carousel autoplay arrows :dots="true" class="mb-0 pb-0" dotsClass="text-primary" style="background-color: #FFFFFF !important">

												<template #prevArrow>
													<div class="custom-slick-arrow" style="left: 10px; z-index: 1; color: white">
														<font-awesome-icon icon="fa-solid fa-chevron-left" class="text-primary" style="color: #656090 !important; font-size: 25px; position: absolute; top: -2px"/>
													</div>
												</template>
												<template #nextArrow>
													<div class="custom-slick-arrow" style="right: 10px; color: white">
														<font-awesome-icon icon="fa-solid fa-chevron-right" class="text-primary" style="color: #656090 !important; font-size: 25px; position: absolute; top: -2px"/>
													</div>
												</template>


												<div class="px-20 pt-10 mb-0 pb-0" style="background-color: #FFFFFF !important">
													<a-row :gutter="24" class="">
														<a-col :span="4" class=""></a-col>
														<a-col :span="4" class="px-20 pt-10">
															<a-avatar src="https://images.pexels.com/photos/415829/pexels-photo-415829.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500" :size="150" style=""/>
														</a-col>
														<a-col :span="2" class=""></a-col>
														<a-col id="cardCarosel" :span="10" class="px-20 py-20 mt-10">
															<span style="font-weight: 400; color: #54398D !important; font-size: 14px;">
																“ Our classes on Silabu reach about 4,000 students studying through their digital devices. Silabu has enabled us to maximize our impact by educating way more students ”
															</span>
															<br/>
															<br/>
															<span class="mt-20" style="font-size: 15px; font-weight: 500; color: #54398D;">
																Jackline Masika,<i> School Owner </i>
															</span>
														</a-col>
														<a-col :span="4" class=""></a-col>
													</a-row>
												</div>

												<div class="px-20 pt-10 mb-0 pb-0" style="background-color: #FFFFFF !important">
													<a-row :gutter="24" class="">
														<a-col :span="4" class=""></a-col>
														<a-col :span="4" class="px-20 pt-10">
															<a-avatar src="https://images.pexels.com/photos/415829/pexels-photo-415829.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500" :size="150" style=""/>
														</a-col>
														<a-col :span="2" class=""></a-col>
														<a-col id="cardCarosel" :span="10" class="px-20 py-20 mt-10">
															<span style="font-weight: 400; color: #54398D !important; font-size: 14px;">
																“ Our classes on Silabu reach about 4,000 students studying through their digital devices. Silabu has enabled us to maximize our impact by educating way more students ”
															</span>
															<br/>
															<br/>
															<span class="mt-20" style="font-size: 15px; font-weight: 500; color: #54398D;">
																Jackline Masika,<i> School Owner </i>
															</span>
														</a-col>
														<a-col :span="4" class=""></a-col>
													</a-row>
												</div>


												<div class="px-20 pt-10 mb-0 pb-0" style="background-color: #FFFFFF !important">
													<a-row :gutter="24" class="">
														<a-col :span="4" class=""></a-col>
														<a-col :span="4" class="px-20 pt-10">
															<a-avatar src="https://images.pexels.com/photos/415829/pexels-photo-415829.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500" :size="150" style=""/>
														</a-col>
														<a-col :span="2" class=""></a-col>
														<a-col id="cardCarosel" :span="10" class="px-20 py-20 mt-10">
															<span style="font-weight: 400; color: #54398D !important; font-size: 14px;">
																“ Our classes on Silabu reach about 4,000 students studying through their digital devices. Silabu has enabled us to maximize our impact by educating way more students ”
															</span>
															<br/>
															<br/>
															<span class="mt-20" style="font-size: 15px; font-weight: 500; color: #54398D;">
																Jackline Masika,<i> School Owner </i>
															</span>
														</a-col>
														<a-col :span="4" class=""></a-col>
													</a-row>
												</div>

											</a-carousel>
										</a-col> -->

										<a-col :span="24" class="mt-20 pt-20 text-center">
											<a-button type="primary" @click="navigateTo('home')" style="margin-top: 40px; border-radius: 10px; font-size: 16px; padding-left: 50px; padding-right: 50px; height: 45px" class="">
												Administer your school
											</a-button>
										</a-col>
									</a-row>

								</a-col>
								<a-col :span="24" :md="1" :lg="1"></a-col>
								
							</a-row>
						</a-col>
					</a-row>
				</a-card>

				<a-card :bordered="false" class="card-info px-0 mx-0 mt-0 pt-0" style="background-color: #F7F7F7;">

					<a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" style="padding-top: 0px;">
						
						<a-col :span="24" :md="18" :lg="18" :xl="18" class="col-form pt-20 mt-20 px-0">

							<a-row :gutter="[24,24]">
								<a-col :span="24" :md="1" :lg="1"></a-col>
								<a-col :span="24" :md="22" :lg="22" class="px-0 mx-0">
									<a-row :gutter="24" class="px-20 mt-20">
										<a-col :span="24" class="text-center">
											<label style="font-size: 30px; font-weight: 700; color: #54398D">Get Silabu App FREE</label>
										</a-col>

										<a-col :span="24" class="mt-20 pt-10 pb-20 mb-20 text-center">
											<img src="../../../public/images/playstore.png" alt="" width="230" style="height: 85px" class="" @click="toPlayStore"> 
										
											<img src="../../../public/images/appstore.png" alt="" width="230" class="mt-5" style="height: 70px" @click="toAppStore"> 
										</a-col>
									</a-row>

								</a-col>
								<a-col :span="24" :md="1" :lg="1"></a-col>
								
							</a-row>
						</a-col>
					</a-row>
				</a-card>

			</a-col>

			<a-col v-if="loadingSession" :span="24" :md="12" :lg="12" :xl="{span: 6, offset: 0}" class="text-center  justify-items-center" style="padding-top: 150px">

				<a-spin class="text-primary" />

			</a-col>
		</a-row>

		<a-row :gutter="[24,24]" class="mt-0 pt-0" v-if="windowWidth <= 991">

			<a-col :span="24" :md="24" :lg="24" :xl="24" v-if="!loadingSession" class="pt-20 mt-0">

				<a-card :bordered="false" class="card-info px-0 mx-0 mt-0 pt-0" style="padding-top: 35px !important" id="mobHome">

					<a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" style="padding-top: 0px;">
						
						<a-col :span="24" :md="18" :lg="18" :xl="18" class="col-form pt-20 mt-0">

							<a-row :gutter="[24,24]">
								<a-col :span="24" :md="3" :lg="3"></a-col>
								<a-col :span="24" :md="18" :lg="18" class="">
									<!-- <a-row class="text-center">
										<a-col :lg="24">
											<img src="../../../public/images/icon_2.png" alt="" width="80px" height="80px" class="" style="border-radius: 20px; background-color: #54398D;"> 
										</a-col>
									</a-row> -->
									<h1 class="" style="font-weight: 100; font-size: 35px;">Welcome Back, login to continue Learning!</h1>
									<!-- <p class="text-muted text-center mt-0 pt-0">Login to continue</p> -->

									<a-alert
										v-if="error.show"
										:message="error.message"
										type="error"
										closable
										:after-close="handleClose"
										show-icon 
									/>

									<a-form
										id="components-form-demo-normal-login"
										:form="form"
										class="login-form"
										@submit="handleSubmit"
										:hideRequiredMark="true">
										<a-input-group>
											<a-row :gutter="24">
												<a-col :span="5" class="w-100 pr-0 mr-0">
													<a-form-item class="mb-10 w-100" label="Username" :colon="false">
														<vue-country-code
															style="height: 40px; width: 100%; margin-top: 4px;"
															@onSelect="onSelectCountry"
															:enableSearchField="true"
															defaultCountry="tz">
														</vue-country-code>
															<!-- preferredCountries="['tz', 'ke', 'ug', 'za']" -->
													</a-form-item>
												</a-col>
												<a-col :span="19" class="ml-0 pl-1">
													<a-form-item class="mb-10" :colon="false">
														<a-input 
															style="height: 40px; margin-top: 42px; width: 100%;"
															v-decorator="[
															'username',
															{ rules: [{ required: true, message: 'Please input your username!' }] },
															]" placeholder="Email or Phone number" />
													</a-form-item>
												</a-col>
											</a-row>
											
										</a-input-group>
										
										<a-form-item class="mb-5" label="Password" :colon="false">
											<a-input-password
												id="signinInputPassword"
												v-decorator="['password', { rules: [{ required: true, message: 'Please input your password!' }] }, ]" 
												style=""
												type="password" 
												placeholder="Password" />
										</a-form-item>

										<a-row :gutters="24" class="pb-5">
											<a-col :span="24" class="pb-5">
												<a class="text-primary" style="text-decoration: none !important;" :href="`/forgot-password`">
													<span>Forgot password?</span>
												</a>
											</a-col>
										</a-row>

										<a-form-item class="mt-2">
											<a-button :loading="isLoading" type="primary" block html-type="submit" class="login-form-button mt-10 text-white" style="border-radius: 15px; height: 46px;">
												LOGIN
											</a-button>
										</a-form-item>


										<a-row :gutters="24" class="pb-5">
											<a-col :span="24" class="pb-5 text-center">
												<span>New to Silabu?</span>
											</a-col>
										</a-row>
										

										<a-form-item class="mt-2">
											<a-button id="btnBordered" type="primary" block class="login-form-button mt-10 text-white" @click="toSignUpPage" style="border-radius: 20px;">
												Create Account
											</a-button>
										</a-form-item>


										<!-- <a-row :gutters="24" class="pb-5">
											<a-col :span="24" class="pb-5 text-center">
												<a-button id="floatingBtn3" type="primary" @click="toWhatsappLink">
													<font-awesome-icon icon="fa-brands fa-whatsapp" class="" style="font-size: 18px; padding-right: 3px"/><span style="font-size: 14px;">Call us</span>
												</a-button>
											</a-col>
										</a-row> -->
										
									</a-form>
								</a-col>
								<a-col :span="24" :md="3" :lg="3"></a-col>
							</a-row>
						</a-col>
					</a-row>
				</a-card>

				<a-card :bordered="false" class="card-info px-0 mx-0 mt-0 pt-0" style="background-color: #F7F7F7; padding-bottom: 60px" id="mobStudent">

					<a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" style="padding-top: 0px;">
						
						<a-col :span="24" :md="18" :lg="18" :xl="18" class="col-form pt-20 mt-10 px-0">

							<a-row :gutter="[24,24]">
								<a-col :span="24" :md="3" :lg="3"></a-col>
								<a-col :span="24" :md="18" :lg="18" class="px-0 mx-0">
									<a-row :gutter="24" class="px-10">
										<a-col :span="24" class="px-20">
											<label style="font-size: 15px; font-weight: 500; color: #848484">Silabu for Students</label>
										</a-col>

										<a-col :span="24" class="mt-15 px-20">
											<label style="font-size: 26px; font-weight: 700;">Silabu for Students</label>
										</a-col>
									</a-row>

									<a-row :gutter="24">

										<a-col :span="24" :md="24" :lg="24">

											<a-row :gutter="24">
											
												<a-col :span="24" class="mt-20">
													<img src="../../../public/images/student_web.png" alt="" width="100%" style=""> 
												</a-col>

												<a-col :span="24" class="text-center">
													<a-button @click="navigateTo('mobHome')" type="primary" block>
														Start Learning Now
													</a-button>
												</a-col>

											</a-row>

										</a-col>

										<a-col :span="24" :md="24" :lg="24" class="">
											<a-card style="height: 100%;" class="px-0 mx-0">
												<a-row :gutter="24" class="px-20">
													<a-col :span="24" class="mt-10">
														<a-list item-layout="horizontal">
															<template>
															<a-list-item>
																<a-list-item-meta description="Engage with peers, teachers, and lessons seamlessly, anytime, anywhere">
																	<template #title>
																		Learn with Friends
																	</template>
																	<template #avatar>
																		<a-avatar id="card" class="mt-5 bg-white" shape="square" :size="42" style="background: #F4F3FC;">
																			<img src="../../../public/images/friends.png" alt="" width="100%" style=""> 
																		</a-avatar>
																	</template>
																</a-list-item-meta>
															</a-list-item>
															</template>
														</a-list>
													</a-col>

													<a-col :span="24">
														<a-list item-layout="horizontal">
															<template>
															<a-list-item>
																<a-list-item-meta description="Access a wealth of study materials and resources">
																	<template #title>
																		Vast Resource Library
																	</template>
																	<template #avatar>
																		<a-avatar id="card" class="mt-5 bg-white" shape="square" :size="42" style="background: #F4F3FC;">
																			<img src="../../../public/images/library.png" alt="" width="100%" style=""> 
																		</a-avatar>
																	</template>
																</a-list-item-meta>
															</a-list-item>
															</template>
														</a-list>
													</a-col>


													<a-col :span="24">
														<a-list item-layout="horizontal">
															<template>
															<a-list-item>
																<a-list-item-meta description="Learn from skilled educators dedicated to your success">
																	<template #title>
																		Expert Tutoring
																	</template>
																	<template #avatar>
																		
																		<a-avatar id="card" class="mt-5 bg-white" shape="square" :size="42" style="background: #F4F3FC;">
																			<img src="../../../public/images/expert.png" alt="" width="100%" style=""> 
																		</a-avatar>
																	</template>
																</a-list-item-meta>
															</a-list-item>
															</template>
														</a-list>
													</a-col>

												</a-row>

												<a-row :gutter="24" class="">
													<a-col :span="24" class="mt-10 px-0 mx-0 bg-white mt-20 mb-0 pb-0">
														<a-carousel autoplay class="mb-0 pb-0" dotsClass="text-primary">
															<div id="linearGradient" class="px-20 pt-10 mb-0 pb-0">
																<a-col :span="24" class="px-10 pt-20">
																	<img src="../../../public/images/student_1.jpg" alt="" width="" style="height: 50px; width: 50px; border-radius: 80px; display: inline"> 
																	<span class="ml-15" style="font-size: 15px; font-weight: 400; color: #F2E9FB;">Suzana Dastans,<i> Student Form 4 </i></span>
																</a-col>
																
																<a-row :gutter="24" class="px-10 pb-20">
																	<a-col :span="24" class="px-20 py-20 mt-10">
																		<span style="font-weight: normal; color: #FFFFFF !important; font-size: 16px;">
																			“ Introducing Suzana Dastan, a recent graduate of Twiga Secondary School's CSEE program with a Division 2:18 pass. 
																			At the young age of 17, she has already set her sights on becoming a medical doctor and has taken the initiative 
																			to enroll in Pre-Form 5 classes offered by SILABU APP. ”
																		</span>
																	</a-col>
																</a-row>
															</div>
														</a-carousel>
													</a-col>

													<a-col :span="24" class="">
														<a-button type="primary" @click="navigateTo('mobHome')" block style="position: absolute; top: -5px; left: 0px" class="">
															Start Learning Now
														</a-button>
													</a-col>
												</a-row>
												
											</a-card>
										</a-col>
									</a-row>
								</a-col>
								<a-col :span="24" :md="3" :lg="3"></a-col>
								
							</a-row>
						</a-col>
					</a-row>
				</a-card>

				<a-card :bordered="false" class="card-info px-0 mx-0 mt-0 pt-0" style="background-color: #FFFFFF; padding-bottom: 60px" id="mobParent">

					<a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" style="padding-top: 0px;">
						
						<a-col :span="24" :md="18" :lg="18" :xl="18" class="col-form pt-20 mt-10 px-0">

							<a-row :gutter="[24,24]">
								<a-col :span="24" :md="3" :lg="3"></a-col>
								<a-col :span="24" :md="18" :lg="18" class="px-0 mx-0">
									<a-row :gutter="24" class="px-20">
										<a-col :span="24">
											<label style="font-size: 15px; font-weight: 500; color: #848484">Silabu for Parents</label>
										</a-col>

										<a-col :span="24" class="mt-15">
											<label style="font-size: 26px; font-weight: 700;">Take Control of Your Child’s Education</label>
										</a-col>
									</a-row>

									<a-row :gutter="24">

										<a-col :span="24" :md="24" :lg="24">

											<a-row :gutter="24">
											
												<a-col :span="24" class="mt-20">
													<img src="../../../public/images/parent_web.png" alt="" width="100%" style=""> 
												</a-col>

												<a-col :span="24" class="text-center">
													<a-button @click="navigateTo('mobHome')" type="primary" block>
														Request a Tutor Now
													</a-button>
												</a-col>

											</a-row>

										</a-col>

										<a-col :span="24" :md="24" :lg="24" class="">
											<a-card style="height: 100%;" class="px-0 mx-0">
												<a-row :gutter="24" class="px-20">
													<a-col :span="24" class="mt-10">
														<a-list item-layout="horizontal">
															<template>
															<a-list-item>
																<a-list-item-meta description="Access a network of skilled educators dedicated to your children's success">
																	<template #title>
																		Expert Teachers
																	</template>
																	<template #avatar>
																		<a-avatar id="card" class="mt-5 bg-white" shape="square" :size="42" style="background: #F4F3FC;">
																			<img src="../../../public/images/expert.png" alt="" width="100%" style=""> 
																		</a-avatar>
																	</template>
																</a-list-item-meta>
															</a-list-item>
															</template>
														</a-list>
													</a-col>

													<a-col :span="24">
														<a-list item-layout="horizontal">
															<template>
															<a-list-item>
																<a-list-item-meta description="Stay informed. Track your child's progress with our Progress Reports. Real insights, real improvement">
																	<template #title>
																		Progress Insights
																	</template>
																	<template #avatar>
																		<a-avatar id="card" class="mt-5 bg-white" shape="square" :size="42" style="background: #F4F3FC;">
																			<img src="../../../public/images/progress.png" alt="" width="100%" style=""> 
																		</a-avatar>
																	</template>
																</a-list-item-meta>
															</a-list-item>
															</template>
														</a-list>
													</a-col>


													<a-col :span="24">
														<a-list item-layout="horizontal">
															<template>
															<a-list-item>
																<a-list-item-meta description="Effortlessly oversee multiple children's education in one place">
																	<template #title>
																		Manage with Ease
																	</template>
																	<template #avatar>
																		
																		<a-avatar id="card" class="mt-5 bg-white" shape="square" :size="42" style="background: #F4F3FC;">
																			<img src="../../../public/images/manage.png" alt="" width="100%" style=""> 
																		</a-avatar>
																	</template>
																</a-list-item-meta>
															</a-list-item>
															</template>
														</a-list>
													</a-col>

												</a-row>

												<a-row :gutter="24" class="">
													<a-col :span="24" class="mt-10 px-0 mx-0 bg-white mt-20 mb-0 pb-0">
														<a-carousel autoplay class="mb-0 pb-0" dotsClass="text-primary">
															<div id="linearGradient" class="px-20 pt-10 mb-0 pb-0">
																<a-col :span="24" class="px-20 pt-20">
																	<img src="../../../public/images/parent_1.jpeg" alt="" width="" style="height: 50px; width: 50px; border-radius: 80px; display: inline"> 
																	<span class="ml-15" style="font-size: 15px; font-weight: 400; color: #F2E9FB;">Joyce Lucas,<i> Parent </i></span>
																</a-col>
																
																<a-row :gutter="24" class="px-10 pb-20">
																	<a-col :span="24" class="px-20 py-20 mt-10">
																		<span style="font-weight: normal; color: #FFFFFF !important; font-size: 16px;">
																			“ SILABU has been a game changer for my child's education. The program's personalized approach to 
																			tutoring has helped my child understand difficult concepts and improve their grades. I am so 
																			impressed with the progress my child has made thanks to SILABU. ”
																		</span>
																	</a-col>
																</a-row>
															</div>

															<div id="linearGradient" class="px-20 pt-10 mb-0 pb-0">
																<a-col :span="24" class="px-20 pt-20">
																	<img src="../../../public/images/parent_2.jpeg" alt="" width="" style="height: 50px; width: 50px; border-radius: 80px; display: inline"> 
																	<span class="ml-15" style="font-size: 15px; font-weight: 400; color: #F2E9FB;">Joyce Lucas,<i> Parent </i></span>
																</a-col>
																
																<a-row :gutter="24" class="px-10 pb-20">
																	<a-col :span="24" class="px-20 py-20 mt-10">
																		<span style="font-weight: normal; color: #FFFFFF !important; font-size: 16px;">
																			“ SILABU has been a lifesaver for my child's education. The program's personalized approach to 
																			tutoring has helped my child excel in their studies and achieve their academic goals. I highly 
																			recommend SILABU to any parent looking for a high-quality and effective tutoring program. ”
																		</span>
																	</a-col>
																</a-row>
															</div>

														</a-carousel>
													</a-col>

													<a-col :span="24" class="">
														<a-button type="primary" @click="navigateTo('mobHome')" block style="position: absolute; top: -5px; left: 0px" class="">
															Request a Tutor Now
														</a-button>
													</a-col>
												</a-row>
												
											</a-card>
										</a-col>
									</a-row>
								</a-col>
								<a-col :span="24" :md="3" :lg="3"></a-col>
								
							</a-row>
						</a-col>
					</a-row>
				</a-card>

				<a-card :bordered="false" class="card-info px-0 mx-0 mt-0 pt-0" style="background-color: #F7F7F7; padding-bottom: 60px" id="mobTeacher">

					<a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" style="padding-top: 0px;">
						
						<a-col :span="24" :md="18" :lg="18" :xl="18" class="col-form pt-20 mt-10 px-0">

							<a-row :gutter="[24,24]">
								<a-col :span="24" :md="3" :lg="3"></a-col>
								<a-col :span="24" :md="18" :lg="18" class="px-0 mx-0">
									<a-row :gutter="24" class="px-20">
										<a-col :span="24">
											<label style="font-size: 15px; font-weight: 500; color: #848484">Silabu for Teachers</label>
										</a-col>

										<a-col :span="24" class="mt-15">
											<label style="font-size: 26px; font-weight: 700;">Earn a Living Doing What You Love</label>
										</a-col>
									</a-row>

									<a-row :gutter="24">

										<a-col :span="24" :md="24" :lg="24">

											<a-row :gutter="24">
											
												<a-col :span="24" class="mt-20">
													<img src="../../../public/images/teacher_web.png" alt="" width="100%" style=""> 
												</a-col>

												<a-col :span="24" class="text-center">
													<a-button @click="navigateTo('mobHome')" type="primary" block>
														Start Teacching Now
													</a-button>
												</a-col>

											</a-row>

										</a-col>

										<a-col :span="24" :md="24" :lg="24" class="">
											<a-card style="height: 100%;" class="px-0 mx-0">
												<a-row :gutter="24" class="px-20">
													<a-col :span="24" class="mt-10">
														<a-list item-layout="horizontal">
															<template>
															<a-list-item>
																<a-list-item-meta description="Get paid for your tutoring services with no delays">
																	<template #title>
																		Instant Payments
																	</template>
																	<template #avatar>
																		<a-avatar id="card" class="mt-5 bg-white" shape="square" :size="42" style="background: #F4F3FC;">
																			<img src="../../../public/images/wallet.png" alt="" width="100%" style=""> 
																		</a-avatar>
																	</template>
																</a-list-item-meta>
															</a-list-item>
															</template>
														</a-list>
													</a-col>

													<a-col :span="24">
														<a-list item-layout="horizontal">
															<template>
															<a-list-item>
																<a-list-item-meta description="Reach students worldwide, breaking down geographical barriers">
																	<template #title>
																		Expanded Reach
																	</template>
																	<template #avatar>
																		<a-avatar id="card" class="mt-5 bg-white" shape="square" :size="42" style="background: #F4F3FC;">
																			<img src="../../../public/images/expanded.png" alt="" width="100%" style=""> 
																		</a-avatar>
																	</template>
																</a-list-item-meta>
															</a-list-item>
															</template>
														</a-list>
													</a-col>


													<a-col :span="24">
														<a-list item-layout="horizontal">
															<template>
															<a-list-item>
																<a-list-item-meta description="Streamline administrative tasks, like scheduling and progress tracking">
																	<template #title>
																		Efficiency Boost
																	</template>
																	<template #avatar>
																		
																		<a-avatar id="card" class="mt-5 bg-white" shape="square" :size="42" style="background: #F4F3FC;">
																			<img src="../../../public/images/efficiency.png" alt="" width="100%" style=""> 
																		</a-avatar>
																	</template>
																</a-list-item-meta>
															</a-list-item>
															</template>
														</a-list>
													</a-col>

													
												</a-row>

												<a-row :gutter="24" class="">
													<a-col :span="24" class="mt-10 px-0 mx-0 bg-white mt-20 mb-0 pb-0">
														<a-carousel autoplay class="mb-0 pb-0" dotsClass="text-primary">
															<div id="linearGradient" class="px-20 pt-10 mb-0 pb-0">
																<a-col :span="24" class="px-20 pt-20">
																	<img src="../../../public/images/teacher_1.jpeg" alt="" width="" style="height: 50px; width: 50px; border-radius: 80px; display: inline"> 
																	<span class="ml-15" style="font-size: 15px; font-weight: 400; color: #F2E9FB;">Mwl. Maeda,<i> Teacher at Open University TZ </i></span>
																</a-col>
																
																<a-row :gutter="24" class="px-10 pb-20">
																	<a-col :span="24" class="px-20 py-20 mt-10">
																		<span style="font-weight: normal; color: #FFFFFF !important; font-size: 16px;">
																			“ Ever since I joined SilabuApp, I have been receiving many students who need to be taught the Kiswahili subject. Silabu has also connected me with many parents whom I would not have met and taught their children without Silabu. Indeed, Silabu is a one-stop centre for many in the field of education. ”
																		</span>
																	</a-col>
																</a-row>
															</div>

														</a-carousel>
													</a-col>

													<a-col :span="24" class="">
														<a-button type="primary" @click="navigateTo('mobHome')" block style="position: absolute; top: -5px; left:0px" class="">
															Start Teaching Now
														</a-button>
													</a-col>
												</a-row>
												
											</a-card>
										</a-col>
									</a-row>
								</a-col>
								<a-col :span="24" :md="3" :lg="3"></a-col>
								
							</a-row>
						</a-col>
					</a-row>
				</a-card>

				<a-card :bordered="false" class="card-info px-0 mx-0 mt-0 pt-0" style="background-color: #FFFFFF; padding-bottom: 60px" id="mobSchool">

					<a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" style="padding-top: 0px;">
						
						<a-col :span="24" :md="18" :lg="18" :xl="18" class="col-form pt-20 mt-10 px-0">

							<a-row :gutter="[24,24]">
								<a-col :span="24" :md="3" :lg="3"></a-col>
								<a-col :span="24" :md="18" :lg="18" class="px-0 mx-0">
									<a-row :gutter="24" class="px-20">
										<a-col :span="24">
											<label style="font-size: 15px; font-weight: 500; color: #848484">Silabu for Schools</label>
										</a-col>

										<a-col :span="24" class="mt-15">
											<label style="font-size: 26px; font-weight: 700;">Take Education to Places Beyond Reach</label>
										</a-col>
									</a-row>

									<a-row :gutter="24">

										<a-col :span="24" :md="24" :lg="24">

											<a-row :gutter="24">
											
												<a-col :span="24" class="mt-20">
													<img src="../../../public/images/school_web.png" alt="" width="100%" style=""> 
												</a-col>

												<a-col :span="24" class="text-center">
													<a-button @click="navigateTo('mobHome')" type="primary" block>
														Administer your school
													</a-button>
												</a-col>

											</a-row>

										</a-col>

										<a-col :span="24" :md="24" :lg="24" class="">
											<a-card style="height: 100%;" class="px-0 mx-0">
												<a-row :gutter="24" class="px-20">
													<a-col :span="24" class="mt-10">
														<a-list item-layout="horizontal">
															<template>
															<a-list-item>
																<a-list-item-meta description="Seamlessly transition to online learning for enhanced reach and efficiency">
																	<template #title>
																		Digitize classes
																	</template>
																	<template #avatar>
																		<a-avatar id="card" class="mt-5 bg-white" shape="square" :size="42" style="background: #F4F3FC;">
																			<img src="../../../public/images/online.png" alt="" width="100%" style=""> 
																		</a-avatar>
																	</template>
																</a-list-item-meta>
															</a-list-item>
															</template>
														</a-list>
													</a-col>

													<a-col :span="24">
														<a-list item-layout="horizontal">
															<template>
															<a-list-item>
																<a-list-item-meta description="Easily oversee multiple classes, teachers, and students from one platform">
																	<template #title>
																		Effortless Management
																	</template>
																	<template #avatar>
																		<a-avatar id="card" class="mt-5 bg-white" shape="square" :size="42" style="background: #F4F3FC;">
																			<img src="../../../public/images/manage.png" alt="" width="100%" style=""> 
																		</a-avatar>
																	</template>
																</a-list-item-meta>
															</a-list-item>
															</template>
														</a-list>
													</a-col>


													<a-col :span="24">
														<a-list item-layout="horizontal">
															<template>
															<a-list-item>
																<a-list-item-meta description="Tailor courses and content to meet the unique needs of your institution">
																	<template #title>
																		Customized Learning
																	</template>
																	<template #avatar>
																		
																		<a-avatar id="card" class="mt-5 bg-white" shape="square" :size="42" style="background: #F4F3FC;">
																			<img src="../../../public/images/equalizer.png" alt="" width="100%" style=""> 
																		</a-avatar>
																	</template>
																</a-list-item-meta>
															</a-list-item>
															</template>
														</a-list>
													</a-col>

												</a-row>

												<a-row :gutter="24" class="">
													<!-- <a-col :span="24" class="mt-10 px-0 mx-0 bg-white mt-20 mb-0 pb-0">
														<a-carousel autoplay class="mb-0 pb-0" dotsClass="text-primary">
															<div id="linearGradient" class="px-20 pt-10 mb-0 pb-0">
																<a-col :span="24" class="px-20 pt-20">
																	<a-avatar src="https://images.pexels.com/photos/415829/pexels-photo-415829.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500" :size="40" style=""/>
																	<span class="ml-15" style="font-size: 15px; font-weight: 400; color: #F2E9FB;">Jackline Masika,<i> School Owner </i></span>
																</a-col>
																
																<a-row :gutter="24" class="px-10 pb-20">
																	<a-col :span="24" class="px-20 py-20 mt-10">
																		<span style="font-weight: normal; color: #FFFFFF !important; font-size: 16px;">
																			“ Our classes on Silabu reach about 4,000 students studying  through their digital devices. Silabu has enabled us to maximize our impact by educating way more students ”
																		</span>
																	</a-col>
																</a-row>
															</div>

															<div id="linearGradient" class="px-20 pt-10 mb-0 pb-0">
																<a-col :span="24" class="px-20 pt-20">
																	<a-avatar src="https://images.pexels.com/photos/415829/pexels-photo-415829.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500" :size="40" style=""/>
																	<span class="ml-15" style="font-size: 15px; font-weight: 400; color: #F2E9FB;">Jackline Masika,<i> School Owner </i></span>
																</a-col>
																
																<a-row :gutter="24" class="px-10 pb-20">
																	<a-col :span="24" class="px-20 py-20 mt-10">
																		<span style="font-weight: normal; color: #FFFFFF !important; font-size: 16px;">
																			“ Our classes on Silabu reach about 4,000 students studying  through their digital devices. Silabu has enabled us to maximize our impact by educating way more students ”
																		</span>
																	</a-col>
																</a-row>
															</div>

															<div id="linearGradient" class="px-20 pt-10 mb-0 pb-0">
																<a-col :span="24" class="px-20 pt-20">
																	<a-avatar src="https://images.pexels.com/photos/415829/pexels-photo-415829.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500" :size="40" style=""/>
																	<span class="ml-15" style="font-size: 15px; font-weight: 400; color: #F2E9FB;">Jackline Masika,<i> School Owner </i></span>
																</a-col>
																
																<a-row :gutter="24" class="px-10 pb-20">
																	<a-col :span="24" class="px-20 py-20 mt-10">
																		<span style="font-weight: normal; color: #FFFFFF !important; font-size: 16px;">
																			“ Our classes on Silabu reach about 4,000 students studying  through their digital devices. Silabu has enabled us to maximize our impact by educating way more students ”
																		</span>
																	</a-col>
																</a-row>
															</div>

															<div id="linearGradient" class="px-20 pt-10 mb-0 pb-0">
																<a-col :span="24" class="px-20 pt-20">
																	<a-avatar src="https://images.pexels.com/photos/415829/pexels-photo-415829.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500" :size="40" style=""/>
																	<span class="ml-15" style="font-size: 15px; font-weight: 400; color: #F2E9FB;">Jackline Masika,<i> School Owner </i></span>
																</a-col>
																
																<a-row :gutter="24" class="px-10 pb-20">
																	<a-col :span="24" class="px-20 py-20 mt-10">
																		<span style="font-weight: normal; color: #FFFFFF !important; font-size: 16px;">
																			“ Our classes on Silabu reach about 4,000 students studying  through their digital devices. Silabu has enabled us to maximize our impact by educating way more students ”
																		</span>
																	</a-col>
																</a-row>
															</div>
														</a-carousel>
													</a-col> -->

													<!-- <a-col :span="24" class="">
														<a-button @click="navigateTo('home')" type="primary" block style="position: absolute; top: 5px; left: 0px" class="">
															Administer your school
														</a-button>
													</a-col> -->
												</a-row>
												
											</a-card>
										</a-col>
									</a-row>
								</a-col>
								<a-col :span="24" :md="3" :lg="3"></a-col>
								
							</a-row>
						</a-col>
					</a-row>
				</a-card>

				<a-card :bordered="false" class="card-info px-0 mx-0 mt-0 pt-0" style="background-color: #FFFFFF;">

					<a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" style="padding-top: 0px;">
						
						<a-col :span="24" :md="18" :lg="18" :xl="18" class="col-form pt-20 mt-20 px-0">

							<a-row :gutter="[24,24]">
								<a-col :span="24" :md="1" :lg="1"></a-col>
								<a-col :span="24" :md="22" :lg="22" class="px-0 mx-0">
									<a-row :gutter="24" class="px-20">
										<a-col :span="24" class="text-center">
											<label style="font-size: 25px; font-weight: 700; color: #54398D">Get Silabu App FREE</label>
										</a-col>

										<a-col :span="24" class="mt-20 text-center">
											<img src="../../../public/images/playstore.png" alt="" width="54%" style="height: 80px" @click="toPlayStore"> 
										</a-col>

										<a-col :span="24" class="mt-20 text-center">
											<img src="../../../public/images/appstore.png" alt="" width="50%" style="height: 70px" @click="toAppStore"> 
										</a-col>
									</a-row>

								</a-col>
								<a-col :span="24" :md="1" :lg="1"></a-col>
								
							</a-row>
						</a-col>
					</a-row>
				</a-card>

			</a-col>



			<a-col v-if="loadingSession" :span="24" :md="12" :lg="12" :xl="{span: 6, offset: 0}" class="text-center  justify-items-center" style="padding-top: 150px">

				<a-spin class="text-primary" />

			</a-col>
		</a-row>

		

		<a-button id="floatingBtn3" type="primary" @click="toWhatsappLink">
			<font-awesome-icon icon="fa-brands fa-whatsapp" class="" style="font-size: 18px; padding-right: 3px"/><span style="font-size: 14px;">Call us</span>
		</a-button>
		
		<!-- 

			<a-button id="floatinWhatsappBtn" type="primary" shape="circle" @click="toWhatsappLink">
			<font-awesome-icon icon="fa-brands fa-whatsapp" class="" style="font-size: 18px; padding-left: 5px"/> Call us
		</a-button>

		<a-button id="floatingBtn1" type="primary" shape="circle" v-if="windowWidth > 991" @click="toLinkedinLink">
			<font-awesome-icon icon="fa-brands fa-linkedin" class="" style="font-size: 18px; padding-left: 5px"/>
		</a-button>

		<a-button id="floatingBtn2" type="primary" shape="circle" v-if="windowWidth > 991" @click="toInstagramLink">
			<font-awesome-icon icon="fa-brands fa-instagram" class="" style="font-size: 18px; padding-left: 5px"/>
		</a-button>

		 -->

		
	</div>
</template>

<script>
	import VueJwtDecode from 'vue-jwt-decode'
	import VueCountryCode from "vue-country-code-select";
	// import VueFab from 'vue-float-action-button'

	export default ({
		components: {
			VueCountryCode,
			// VueFab,
		},

		data() {
			return {
				// Binded model property for "Sign In Form" switch button for "Remember Me" .
				error: {
					message: null,
					show: false,
				},

				windowWidth: window.innerWidth,

				isSmallWindow: true,

				loadingSession: false,

				countryCode: '255',

				isLoading: false,

				userType: {
					showModal: false,
					type: null,
				}
			}
		},
		mounted() {
			this.$nextTick(() => {
				window.addEventListener('resize', this.onResize);
			})
		},
		beforeDestroy() { 
			window.removeEventListener('resize', this.onResize); 
		},
		beforeCreate() {
			// Creates the form and adds to it component's "form" property.
			this.form = this.$form.createForm(this, { name: 'normal_login' });
		},
		created() {
			this.$root.$refs.HomePage = this;
			// this.checkUserSession()
		},
		methods: {

			toPlayStore() {
				window.open(`https://play.google.com/store/apps/details?id=com.silabu.silabuapp`, '_blank');
			},

			toAppStore() {
				window.open(`https://apps.apple.com/au/app/silabu/id1628579393`, '_blank');
			},

			toWhatsappLink() {
				window.open(`https://wa.me/255752156253`, '_blank');
			},

			toLinkedinLink() {
				
			},

			toInstagramLink() {
				
			},

			onResize() {
				this.windowWidth = window.innerWidth
			},

			navigateTo(refName) {
				const el = document.getElementById(refName);
                el.scrollIntoView({behavior: "smooth"});
			},

			toSignUpPage() {
				this.$router.push(`/sign-up`)
			},

			async checkUserSession() {

				this.loadingSession = true;

				const userInfo = await localStorage.getItem('user_details');
				const token = await localStorage.getItem('user_token');

				console.log('========================>')
				console.log(userInfo)
				console.log(userInfo == 'null')
				console.log(token)
				console.log(userInfo != null && token != null)
				
				if((userInfo != null || userInfo != 'null') && (token != null || token != 'null')) {
					
					let userDetails = JSON.parse(userInfo);

					console.log(userDetails)

					if(userDetails != null && userDetails.uuid != null) {
						this.$router.push('/home'); //{ path: '/home' }).then(() => { this.$router.go() })
						this.loadingSession = false;
					}
					this.loadingSession = false;
				}else{
					this.loadingSession = false;
				}
				
			},

			onSelectCountry({name, iso2, dialCode}) {
				this.countryCode = dialCode;
			},

			handleClose() {
				this.error.show = false;
			},

			// Handles input validation after submission.
			handleSubmit(e) {
				e.preventDefault();
				this.form.validateFields((err, values) => {
					if ( !err ) {
						this.error.show = false;
						this.error.message = null;
						this.isLoading = true;

						const constUsername = values.username;
						let username = '';
						const password = values.password;

						// console.log(constUsername.substring(0, 4));

						if(!constUsername.includes("@")){

							if(constUsername.substring(0, 4).includes(this.countryCode) && !constUsername.includes("+")) {
								username = "+" + constUsername;
							}

							else if(constUsername.substring(0, 4).includes(this.countryCode) && constUsername.includes("+")) {
								username =  constUsername;
							}

							else if(constUsername[0] == "0" || constUsername[0] == 0) {
								username = "+" + this.countryCode + "" + constUsername.substring(1)
							}

							else if(constUsername[0] != "0" || constUsername[0] != 0) {
								username = "+" + this.countryCode + "" + constUsername
							}

							else {
								username = constUsername;
							}

						}else{
							username = constUsername;
						}

						let url = `${this.$BACKEND_URL}/auths/authenticate`;
						
						this.$AXIOS.post(url, { username, password }).then(async(response) => {
							if (response.status >= 200 && response.status < 210) {

								await localStorage.setItem("user_token", response.data.data.token);

								let userInfo = VueJwtDecode.decode(response.data.data.token);

								console.log(userInfo);
								if(userInfo != null) {
									console.log(userInfo.data.verified)
									if(userInfo.data.verified) {
										this.isLoading = false;
										await localStorage.setItem("user_details", JSON.stringify(userInfo.data));

										this.$router.push({ path: '/home' }).then(() => { this.$router.go() });
									}else {
										this.$router.push({path: `/verify-account/${username}`});
									}
								}
								
								this.isLoading = false;
								// let url = await window.localStorage.getItem("user_details");
								// console.log(url)

								// 

								// if(url != null && url.includes('class')) {
								// 	// this.$router.replace(url)
								// 	this.$router.push(`/home`);
								// }else{
								// 	this.$router.push(`/home`);
								// }
								
							}
							this.isLoading = false
						}).catch((err) => {
							console.log(err)
							this.isLoading = false;
							
							this.error.message = err.response != null  && err.response.data != null ? err.response.data.message : "Connection error";
							this.error.show = true
						});
					}
				});
			},

			selectAccountType() {
				this.userType.showModal = true;
			},

			handleUserTypeSelection(type) {
				this.userType.type = type;
			},

			goToSignUp() {
				if(this.userType.type != null && this.userType.type == 'student') {
					this.$router.push("/sign-up-student")
				}

				if(this.userType.type != null && this.userType.type == 'tutor') {
					this.$router.push("/sign-up-tutor")
				}

				if(this.userType.type != null && this.userType.type == 'parent') {
					this.$router.push("/sign-up-parent")
				}

				if(this.userType.type != null && this.userType.type == 'institute') {
					this.$router.push("/sign-up-institute")
				}
			}
		},
	})

</script>

<style lang="scss" scoped>
	body {
		background-color: #ffffff;
	}
	#signinBtn, #signinBtn:hover {
		background-color: #632163 !important;
		color: white !important;
	}
	#normal_login_password.ant-input {
		height: 50px !important;
	}

	#card {
		box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14),
                  0 1px 18px 0 rgba(0, 0, 0, 0.12),
                  0 3px 5px -1px rgba(0, 0, 0, 0.1);


		border-radius: 12px;
	}

	#cardCarosel {
		box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14),
                  0 1px 18px 0 rgba(0, 0, 0, 0.12),
                  0 3px 5px -1px rgba(0, 0, 0, 0.1);


		border-radius: 12px;
	}

	#linearGradient {
		background: linear-gradient(150.28deg, #6A45AA 68.04%, rgba(106, 69, 170, 0.831448) 80.22%, rgba(106, 69, 170, 0.727899) 98.24%, rgba(125, 80, 202, 0) 100%);
	}

	
  .slick-dots {
    position: static;
    margin-top: 50px;

    li button {
      height: 16px;
      border-radius: 4px;
      background-color: gray;
    }

}

.ant-carousel .slick-dots li, .ant-carousel .slick-dots li.slick-active button, .slick-dots > li {
	height: 16px;
      border-radius: 4px !important;
      background-color: red !important;
}

.layout-default .ant-layout-header .header-nav a.router-link-active {
	color: red; 
    font-weight: 600
}

#floatingBtn1 {
	position:fixed;
	left: 50px;
	bottom: 100px;
	background-color: #0077B5 !important;
	border-color: #0077B5 !important;
}

#floatingBtn {
	position:fixed;
	left: 50px;
	bottom: 160px;
	background: radial-gradient(circle farthest-corner at 35% 90%, #fec564, transparent 50%), radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%), radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent), linear-gradient(#6559ca, #bc318f 30%, #e33f5f 50%, #f77638 70%, #fec66d 100%) !important;
	border-color: #fec564 !important;
}

#floatinWhatsappBtn {
	position:fixed;
	right: 50px;
	bottom: 160px;
	background-color: #29A81A !important;
	border-color: #29A81A !important;
	color: #FFFFFF;
	border-radius: 20px;
}

#floatingBtn3 {
	position:fixed;
	right: 80px;
	bottom: 160px;
	background-color: #29A81A !important;
	border-color: #29A81A !important;
	color: #FFFFFF;
	border-radius: 20px;
}
	
</style>
